import { useTranslation } from 'react-i18next';

export const useValidation = () => {
  const { t } = useTranslation();

  const email = {
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: t('please enter a valid email address'),
    },
  };

  const required = {
    required: { value: true, message: t('this field is required') },
  };

  return { email, required };
};
