import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss'; // App.scss must be imported before other components
import Reports from 'pages/reports/Reports';
import Overview from 'pages/overview/Overview';
import Uploads from 'pages/uploads/Uploads';
import Login from 'pages/login/Login';
import { ProtectedRoute } from 'auth/protectedRoute';
import { SessionTimeout } from 'auth/sessionTimeout';
import SendCode from 'pages/login/SendCode';
import ResetPassword from 'pages/login/ResetPassword';
import Indicators from 'pages/indicators/Indicators';
import NotFound from 'pages/notFound/NotFound';
import { LoginLayout } from 'pages/login/LoginLayout';
import { ROLES } from 'auth/accountProvider';
import { AppLayout } from 'pages/AppLayout';

function App() {
  return (
    <Router>
      <div className="app">
        <SessionTimeout />
        <Routes>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/send-code" element={<SendCode />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<AppLayout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Overview />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/indicators" element={<Indicators />} />
              <Route path="/*" element={<NotFound />} />
            </Route>
            <Route element={<ProtectedRoute roles={[ROLES.UPLOAD]} />}>
              <Route path="/upload" element={<Uploads />} />
            </Route>
          </Route>
        </Routes>

        {/* Cookie button must always stay mounted to work properly */}
        <button id="ot-sdk-btn" className="ot-sdk-show-settings"></button>
      </div>
    </Router>
  );
}

export default App;
